import styled from "styled-components";
import { AllBanksHomeCardStyleProps } from "./all-banks-card.atom";

const RegistrationAllBanksHomeCardStyled = styled.div<AllBanksHomeCardStyleProps>`
  box-shadow: 0px 0px 5px rgba(204, 204, 204, 0.25);
  border-radius: 20px;

  background: linear-gradient(
    to bottom,
    #ffffff,
    93%,
    ${({ borderColor }) => borderColor} 93%,
    ${({ borderColor }) => borderColor} 100%
  );

  hr {
    margin: 0px 0px;
    background: rgba(168, 170, 172, 0.35);
  }
`;

const CardHeaderAllBanks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;

  @media (max-width: 991px) {
    gap: 30px;
  }
`;

const BackgroundLogoAllBanks = styled.div`
  background: #0e5970;
  width: 95px;
  height: 61px;
  border-radius: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardReward = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;

  @media (max-width: 991px) {
    gap: 30px;
  }
`;

const LogoWrapperAllBanks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 43px;
`;

const HeaderExtraCardHomeAllBanks = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TitleExtraCardHomeAllBanks = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: ${({ theme }) => theme.colors.Dark};

  @media (min-width: 992px) and (max-width: 1096px) {
    font-size: 13px;
  }
`;

const CardTextAllBanks = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: ${({ theme }) => theme.colors.Dark};
  margin: 14px 26px;

  @media (min-width: 992px) and (max-width: 1096px) {
    font-size: 11px;
  }
`;

const CardBottomAllBanks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;

  margin: 10px 26px 22px;

  img {
    width: 50px;
    height: 50px;
  }

  div.coming-soon {
    position: relative;
  }

  @media (max-width: 440px) {
    justify-content: start;
  }
`;

const ComingSoonDiv = styled.div`
  position: absolute;
  top: -3px;
  right: -13px;
  padding: 2px 4px;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 10px;

  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors.BlueNavy};
  background: ${({ theme }) => theme.colors.ManheimYellow};
`;

const RegistrationRewardsCox = styled.div<AllBanksHomeCardStyleProps>`
  box-shadow: 0px 0px 5px rgba(204, 204, 204, 0.25);
  border-radius: 20px;

  background: #17a5de;

  hr {
    margin: 0px 0px;
    background: rgba(168, 170, 172, 0.35);
  }
`;

const BackgroundRewardCox = styled.div`
  background: linear-gradient(to bottom, #0a1b2b, #0c2f47, #115c85);
`;

const ContainerReward = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ImageRewardCox = styled.div`
  width: 100%;
`;

const ImageLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageAllReward = styled.img`
  width: 100%;
  height: 205px;
`;

const ButtonRewardCox = styled.button`
  border: none;
  border-radius: 10px;
  width: 100% !important;
  background-color: #f3bc2a;
  color: #003661;
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  a {
    text-decoration: none;
  }
`;

const CardRewardCox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;

  margin: 0px 16px 14px;

  @media (max-width: 440px) {
    justify-content: start;
  }
`;

const TextElements = styled.div`
  display: flex;
  flex-direction: column;

  b {
    color: #f3bc2a;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
  }

  span {
    color: #f3bc2a;
    font-family: "Montserrat", sans-serif;
    font-style: bold;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;

    display: flex;
    justify-content: flex-end;
  }
`;

export {
  RegistrationAllBanksHomeCardStyled,
  CardBottomAllBanks,
  CardHeaderAllBanks,
  BackgroundLogoAllBanks,
  CardTextAllBanks,
  LogoWrapperAllBanks,
  HeaderExtraCardHomeAllBanks,
  TitleExtraCardHomeAllBanks,
  ComingSoonDiv,
  BackgroundRewardCox,
  ImageRewardCox,
  ButtonRewardCox,
  ContainerReward,
  ImageLogo,
  ImageAllReward,
  CardRewardCox,
  TextElements,
  RegistrationRewardsCox,
  CardReward,
};
