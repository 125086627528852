import React, { useMemo } from "react";
import { ICpvHomeBigCardData } from "../../../models";
import {
  RenaveDataStock
} from "../../assets";
import {
  ContainerReward,
  ImageAllReward,
  ImageLogo,
  ImageRewardCox,
  RegistrationRewardsCox
} from "./all-banks-card.atom.style";

interface IProps {
  profile?: string;
  borderColor: string;
  onClick?: (e: React.MouseEvent) => void;
  data: ICpvHomeBigCardData;
  dataElement?: string;
  dataProduct?: string;
  isMaster?: boolean;
}

export interface AllBanksHomeCardStyleProps extends Omit<IProps, "data"> {}

export const AllBanksCardAtom: React.FC<IProps> = (props) => {
  const { data, dataElement, dataProduct, ...otherProps } = props;

  const RenaveDataStockCard = useMemo(
    () => (
      <RegistrationRewardsCox
        {...otherProps}
        data-element={dataElement}
        data-product={dataProduct}
      >
        <ContainerReward>
          <ImageRewardCox>
            <ImageLogo>
              <ImageAllReward src={RenaveDataStock} alt="" />
            </ImageLogo>
          </ImageRewardCox>
        </ContainerReward>
      </RegistrationRewardsCox>
    ),
    [dataElement, dataProduct, otherProps]
  );

  return <>{RenaveDataStockCard}</>;
};
